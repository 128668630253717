.accordion {
  width: 100%;
}

.accordion-item {
  border-bottom: 1px solid #ddd;
}

.accordion-title {
  cursor: pointer;
  padding: 10px;
  background-color: #f5f5f5;
}

.accordion-content {
  padding: 10px;
}
